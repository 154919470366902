





















































import Vue from 'vue';
import { mapGetters } from 'vuex';
import PortfolioLinks from '@/components/base/Links.vue';
import linkMixins from '@/mixins/link.mixin';
import {
  GET_PROJECTS_LIST,
  GET_OPEN_SOURCE_LIST,
} from '../store/types/getters.type';
import TList from '../types/ListType';

export default Vue.extend({
  name: 'Home',
  components: {
    PortfolioLinks,
  },
  mixins: [linkMixins],
  methods: {
    ...mapGetters([GET_PROJECTS_LIST, GET_OPEN_SOURCE_LIST]),
  },
  computed: {
    projects(): TList {
      return this[GET_PROJECTS_LIST]();
    },
    openSource(): TList {
      return this[GET_OPEN_SOURCE_LIST]();
    },
  },
});
