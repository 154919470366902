


































import Vue from 'vue';
import { mapGetters } from 'vuex';

import {
  GET_OPEN_SOURCE,
  GET_OPEN_SOURCE_LIST,
} from '../store/types/getters.type';
import TOpenSource from '../types/OpenSourceType';
import TList from '../types/ListType';

import projectsMixin from '@/mixins/projects.mixin';

export default Vue.extend({
  name: 'open-source',
  methods: {
    ...mapGetters([GET_OPEN_SOURCE, GET_OPEN_SOURCE_LIST]),
  },
  mixins: [projectsMixin],
  computed: {
    project(): TOpenSource {
      const project: TOpenSource =
        this[GET_OPEN_SOURCE]()['items'][(this as any).id] || null;

      return project;
    },
    metaInfo(): TList {
      return this[GET_OPEN_SOURCE_LIST]().filter(
        (el: TList) => el.to === (this as any).id
      )[0];
    },
  },
});
