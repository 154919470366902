























































import Vue from 'vue';
import { mapGetters } from 'vuex';

import PortfolioSeperator from '@/components/base/Seperator.vue';
import PortfolioButton from '@/components/base/Button.vue';
import PortfolioMarkdownRenderer from '@/components/helper/MarkdownRenderer.vue';

import { GET_PROJECTS, GET_PROJECTS_LIST } from '../store/types/getters.type';
import TProject from '../types/ProjectType';
import TList from '../types/ListType';

import projectsMixin from '@/mixins/projects.mixin';

export default Vue.extend({
  name: 'Projects',
  methods: {
    ...mapGetters([GET_PROJECTS, GET_PROJECTS_LIST]),
  },
  mixins: [projectsMixin],
  computed: {
    project(): TProject {
      const project: TProject =
        this[GET_PROJECTS]()['items'][(this as any).id] || null;

      return project;
    },
    metaInfo(): TList {
      return this[GET_PROJECTS_LIST]().filter(
        (el: TList) => el.to === (this as any).id
      )[0];
    },
  },
});
