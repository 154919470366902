

















import Vue from 'vue';
import linkMixin from '@/mixins/link.mixin';

export default Vue.extend({
  name: 'PortfolioLinks',
  props: {
    hasLowContrastBackground: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [linkMixin],
  data: () => ({
    links: [
      {
        img: 'github',
        to: 'https://github.com/Keimeno',
        invertedOnLowContrast: true,
        hasBorder: false,
      },
      {
        img: 'twitter',
        to: 'https://twitter.com/_Keimeno',
        invertedOnLowContrast: false,
        hasBorder: false,
      },
      {
        img: 'stackoverflow',
        to: 'https://stackoverflow.com/users/11098471/Keimeno',
        invertedOnLowContrast: false,
        hasBorder: true,
      },
    ],
  }),
});
