




import Vue from 'vue';
import marked from 'marked';

export default Vue.extend({
  name: 'PortfolioMarkdownRenderer',
  props: {
    markdown: {
      type: String,
      required: true,
    },
  },
  computed: {
    render() {
      return marked(this.markdown);
    },
  },
});
