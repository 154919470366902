
















import Vue from 'vue';
import PortfolioHeader from '@/components/layout/Header.vue';
import PortfolioFooter from '@/components/layout/Footer.vue';

export default Vue.extend({
  name: 'app',
  components: {
    PortfolioHeader,
    PortfolioFooter,
  },
  metaInfo: {
    titleTemplate: 'Portfolio %s',
  },
});
