






































































import Vue from 'vue';
import { mapGetters } from 'vuex';

import PortfolioLinks from '../base/Links.vue';

import linkMixin from '@/mixins/link.mixin';

import TList from '@/types/ListType';

import {
  GET_PROJECTS_LIST,
  GET_OPEN_SOURCE_LIST,
} from '../../store/types/getters.type';

export default Vue.extend({
  name: 'PortfolioHeader',
  components: {
    PortfolioLinks,
  },
  mixins: [linkMixin],
  data: () => ({
    active: false,
    caretDown:
      '<svg height="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title" aria-describedby="desc" role="img" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Angle Down</title><desc>A line styled icon from Orion Icon Library.</desc><path data-name="layer1"fill="none" stroke="#f8f8f8" stroke-miterlimit="10" stroke-width="5" d="M20 26l11.994 14L44 26"stroke-linejoin="round" stroke-linecap="round"></path></svg>',
    activePage: '',
    projects: [] as TList[],
    openSource: [] as TList[],
  }),
  methods: {
    ...mapGetters([GET_PROJECTS_LIST, GET_OPEN_SOURCE_LIST]),
    openInternalLink(name: string, params?: object) {
      (this as any).openInternalLinkByName(name, params);
      (this.$refs['link-projects'] as HTMLDivElement).classList.remove(
        'opened'
      );
      (this.$refs['link-open-source'] as HTMLDivElement).classList.remove(
        'opened'
      );

      (this.$refs['link-projects-items'] as HTMLDivElement).style.height = '0';
      (this.$refs['link-open-source-items'] as HTMLDivElement).style.height =
        '0';

      this.active = false;
    },
    toggleDropdown(ref: string) {
      const refClassList = (this.$refs[ref] as HTMLDivElement).classList;
      const items = this.$refs[`${ref}-items`] as HTMLDivElement;
      const opened = 'opened';

      refClassList.toggle(opened);

      if (refClassList.contains(opened)) {
        items.style.height = items.scrollHeight + 'px';
      } else {
        items.style.height = '0';
      }
    },
    updateActivePage() {
      const path = this.$route.fullPath.split('/');
      this.activePage = path[1];
    },
  },
  mounted() {
    this.updateActivePage();

    // retrieves projects list
    this.projects = this[GET_PROJECTS_LIST]();
    this.openSource = this[GET_OPEN_SOURCE_LIST]();
  },
  watch: {
    $route() {
      this.updateActivePage();
    },
  },
});
