











import Vue from 'vue';
export default Vue.extend({
  name: 'Error',
  computed: {
    error(): string {
      return this.$route.params.message;
    },
  },
});
