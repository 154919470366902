










import Vue from 'vue';

export default Vue.extend({
  name: 'PortfolioSeperator',
  props: {
    marginTop: {
      type: String,
      default: '10',
    },
    marginBottom: {
      type: String,
      default: '30',
    },
  },
});
