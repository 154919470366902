






import Vue from 'vue';
export default Vue.extend({
  name: 'PortfolioButton',
  props: {
    text: {
      type: String,
      default: 'Submit',
    },
  },
});
